
  
  .tabs {
    display: flex;
  }
  
  .tab-container {
    display: flex;
    flex-direction: column;
    width: 400px;
  }
  
  
  .tab {
    cursor: pointer;
    padding: 10px;
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    text-align: center;
    transition: background-color 0.3s;
    border-radius: 5px;
  }
  .tab.active {
    background-color: #ddd;
  }
  
  .tab:hover {
    background-color: #ddd;
  }
  
  .tab-content {
    padding: 20px;
    border: 1px solid #ccc;
    display: none;
    border-radius: 5px;
    width: 900px;
  }
  
  .tab-content.active {
    display: block;
  }
  
  .tab-contents {
    flex-grow: 1; 
    padding: 0px;
  }

  .tab-content button {
    margin: 5px;
    padding: 8px;
    cursor: pointer;
    width: 400px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .tab-content button.selected {
    background-color: #990033;
    color: white;
  }



  .button-container {
    display: flex;
    flex-direction: column; 
    margin-top: 10px;
}
  
  .button-row {
    margin-bottom: 30px;
    display: flex;
    flex-wrap: wrap; 
    gap: 10px; 
    position: relative;
    border: 2px solid #ccc; 
    padding: 20px; 
    border-radius: 10px; 
  }
  .button-label {
    position: absolute; 
    top: -10px;
    left: 10px; 
    background-color: #f2f2f2; 
    padding: 0 5px; 
    font-size: 16px; 
    color: #333; 
}
  

.button {
    flex: 0 0 120px;
    padding: 10px;
    height: 50px;
    margin: 5px;
    border-radius: 5px;
    background-color: #bbb6b6; 
    color: #fff;
    border: none;
    cursor: pointer;
    white-space: normal; 
    overflow: hidden;
    text-overflow: ellipsis;
}

.button.selected {
    background-color: #990033;
}


.custom-menu .dx-menu-item-selected {
  background-color: #990033 !important;
  color: white !important;
}


.satis-kapali {
  color: #990033;
}