#chat-window {
    background-color: rgba(191, 191, 191, 0.15);
    height: 350px;
    padding: 10px;
}

.dx-htmleditor {
    height: 100px;
}

#send {
    float: right;
    margin: 10px 0px;
}

.message {
    height: auto;
    background-color: inherit;
    display: inline-block;
    max-width: 60%;
    border-radius: 5px;
    margin: 5px;
    padding: 10px;
    width: 100%;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
}

.name {
    font-weight: bold;
}

.date {
    opacity: 0.5;
}

.photo {
    float: left;
    height: 100%;
}

.photo > img {
    width: 40px;
    height: 40px;
    display: block;
    border-radius: 40px;
    margin-right: 10px;
}