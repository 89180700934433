#gauge-demo {
    height: 440px;
    width: 100%;
}

#gauge {
    width: 80%;
    height: 100%;
    margin-top: 20px;
    float: left;
}

#panel {
    width: 150px;
    text-align: left;
    margin-top: 20px;
    float: left;
}


.long-title h3 {
    font-weight: 200;
    font-size: 28px;
    text-align: center;
    margin-bottom: 20px;
}

.options {
    padding: 45px;
    position: left;
    bottom: 0;
    right: 0;
    width: 280px;
    height:640px;
    top: 0;
    background-color: rgba(191, 191, 191, 0.15);
}

.ticketeforsayfasi {
    padding: 45px;
    position:relative;
    bottom: 150px;
    left: 100px;
    width: 1000px;
    height:550px;
    top: 0;
    background-color: rgba(191, 191, 191, 0.15);
}
.ticketeforform{
    padding: 45px;
    position:relative;
    bottom: 150px;
    width: 1000px;
    height:550px;
    top: 0;
    background-color: rgba(191, 191, 191, 0.15);
}
.optionss {
    padding: 1px;
    position: absolute;
    bottom: 0;
    right: 0;
    left:320px;
    width:800px;
    top: 0;
    background-color: rgb(255, 255, 255);
}

.message{
    object-position: right bottom;
}

.btncolor{
    background-color: #17a2b7;
    border-radius:20px;
    line-height: 20px;
    text-align:left;
    height: 180px;
    margin: 20px;
    width: 390px;

}
.btncolor2{
    background-color: #27a844;
    border-radius:20px;
    line-height: 20px;
    text-align:left;
    height: 180px;
    margin: 20px;
    width: 390px;
}
.btncolor3{
    background-color: #c62f3e;
    border-radius:20px;
    line-height: 20px;
    text-align:left;
    height: 180px;
    margin: 20px;
    width: 390px;
}

.btnpopup1{
    background-color: #17a2b7;
    border-radius:20px;
    line-height: 20px;
    text-align:left;
    height: 180px;
    margin: 20px;
    width: 390px;
}

.btnpopup2{
    background-color: #27a844;
    border-radius:20px;
    line-height: 20px;
    text-align:left;
    height: 180px;
    margin: 20px;
    width: 390px;
}

.btnpopup3{
    background-color: #c62f3e;
    border-radius:20px;
    line-height: 20px;
    text-align:left;
    height: 180px;
    margin: 20px;
    width: 390px;
}

.btnurun{
    border-radius: 20px;
    text-align: center;
    margin: 20px;
}
.btninfo{
    margin-left: 810px;
}
.btninfosatis{
    margin-left: 1225px;
}
.btnkapat{
    margin-left: 370px; 
}
.btncolordashboarsset{
    border-radius:20px;
    text-align: center;
    margin: 20px;
}
.dashboardswitch {
    vertical-align: middle;
    margin-left: 4px;
  }
.coksatanlarartanazalanswitch  {
    float:unset;
    text-align: center;
    vertical-align: top;
    padding-right: 250px;
}
.coksatanlarmiktaragoreswitch {
    float: left;
    text-align: center;
    line-height: 20px;
    vertical-align: top;
    padding-left:1000px;
}
.coksatanlarmiktaragoreswitchpopup {
    margin-left: 1000px;
    line-height: 20px;
}
.coksatanlarlist > div {
    padding-left: 0px;
    vertical-align: top;
    line-height: 20px;
    font-size: 15px;
    float: left;
}
.coksatanlarlist > div.fiyat {
    float: left;
    text-align: center;
    line-height: 20px;
    vertical-align: top;
    padding-left:1000px;
}
.coksatanlarlist > div.siparis {
    float:unset;
    text-align: center;
    vertical-align: top;
    padding-right: 250px;
}
.list {
    float:bottom;
    background-color: rgb(255, 255, 255);
}

.coksanatlarcharts {
    padding-left: 10px;
    vertical-align: top;
    line-height: 65px;
    font-size: 15px;
    float:bottom;
}
.lds-spinner {
/* change color here */
color: #F44336
}
.lds-spinner,
.lds-spinner div,
.lds-spinner div:after {
box-sizing: border-box;
}
.lds-spinner {
color: currentColor;
display: inline-block;
position: relative;
width: 80px;
height: 80px;
}
.lds-spinner div {
transform-origin: 40px 40px;
animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
content: " ";
display: block;
position: absolute;
top: 3.2px;
left: 36.8px;
width: 6.4px;
height: 17.6px;
border-radius: 20%;
background: currentColor;
}
.lds-spinner div:nth-child(1) {
transform: rotate(0deg);
animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
transform: rotate(30deg);
animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
transform: rotate(60deg);
animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
transform: rotate(90deg);
animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
transform: rotate(120deg);
animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
transform: rotate(150deg);
animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
transform: rotate(180deg);
animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
transform: rotate(210deg);
animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
transform: rotate(240deg);
animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
transform: rotate(270deg);
animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
transform: rotate(300deg);
animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
transform: rotate(330deg);
animation-delay: 0s;
}
@keyframes lds-spinner {
0% {
    opacity: 1;
}
100% {
    opacity: 0;
}
}